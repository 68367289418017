import { useCallback, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import debounce from 'lodash-es/debounce'
import useScreenView from '../ui/use-screen-view'
import { SlugTestProductProps } from '../../pages/tests/[slug].page'
import { BespokeProductProps } from '../../pages/tests/bespoke-test-panel.page'
import { cn } from '../../helpers/tailwind'
import ProductBuyButton, { BuyButtonId } from './product-buy-button'
import { ProductType } from '@/gql'
import * as UI from '@/ui'

type StickyBuyButtonProps = {
  product: SlugTestProductProps['product'] | BespokeProductProps['product']
  selectedModifiers?: Set<string>
  price: number
  disabled?: boolean
}

const StickyBuyButton = ({ product, selectedModifiers, price, disabled }: StickyBuyButtonProps) => {
  const { isMobileView, isDesktopView } = useScreenView()
  const [isBuyButtonVisible, setIsBuyButtonVisible] = useState(false)

  const checkButtonVisibility = useCallback(() => {
    const buttons = [document.getElementById('buyButton_1'), document.getElementById('buyButton_2')]

    const isAnyButtonVisible = buttons.some((button) => {
      if (button) {
        const rect = button.getBoundingClientRect()
        return rect.top < window.innerHeight && rect.bottom >= 0
      }
      return false
    })

    if (isAnyButtonVisible || window.scrollY < threshold) {
      setIsBuyButtonVisible(false)
    } else {
      setIsBuyButtonVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hideBuyButton = useCallback(() => {
    setIsBuyButtonVisible(false)
  }, [])

  const debouncedHideBuyButton = debounce(hideBuyButton, 200)

  useEffect(() => {
    window.addEventListener('scroll', checkButtonVisibility)
    window.addEventListener('resize', checkButtonVisibility)

    checkButtonVisibility()

    return () => {
      window.removeEventListener('scroll', checkButtonVisibility)
      window.removeEventListener('resize', checkButtonVisibility)
      debouncedHideBuyButton.cancel()
    }
  }, [checkButtonVisibility, debouncedHideBuyButton])

  if (!product) return null

  return (
    <>
      {isDesktopView && (
        <div className="fixed top-0 right-0 left-0 z-20 h-0" id="StickyBuyButton_Desktop">
          <Transition
            as="div"
            show={isBuyButtonVisible}
            enter="transition-opacity duration-750"
            enterFrom="opacity-0"
            enterTo="opacity-95"
            leave="transition-opacity duration-750"
            leaveFrom="opacity-95"
            leaveTo="opacity-0"
          >
            <div className="hidden md:block" id="sticky-buy-button">
              <div className="flex justify-center rounded-b-3xl bg-selphWhite-100 px-6 py-2 align-middle shadow-lg drop-shadow-[0_0_12px_rgba(0,0,0,0.15)]">
                <UI.Image
                  src={product.images[0].src}
                  alt={product.images[0].alt}
                  width={80}
                  height={80}
                  className="my-2 rounded-2xl object-cover object-center ring-2 ring-selphOrange-500"
                />

                <UI.Block gap="none" display="flexCol" className="mx-4 justify-between py-2">
                  <UI.Heading as="p" size="xxs" className="tracking-tight">
                    {product.name}
                  </UI.Heading>

                  <div className="flex items-center justify-between gap-x-2">
                    <span className="text-lg text-selphBlack">
                      <UI.Currency value={price} />
                    </span>

                    <UI.Badge size="small" color="outline">
                      Free, next working-day delivery
                    </UI.Badge>
                  </div>
                </UI.Block>

                <ProductBuyButton
                  size="xl"
                  product={{ sku: product.sku, name: product.name, price: product.price, type: product.type }}
                  selectedModifiers={selectedModifiers}
                  id={BuyButtonId.StickyBuyButton_Desktop}
                  disabled={disabled}
                  className="ml-5 self-center text-center lg:ml-40"
                >
                  {product.type === ProductType.LabTest && (
                    <>
                      <span className="text-sm sm:whitespace-nowrap">Includes Money Back Guarantee</span>
                      {product.sku === 'LT-QFBCT' && (
                        <span className="text-sm sm:whitespace-nowrap">& Free Charity Donation</span>
                      )}
                    </>
                  )}
                </ProductBuyButton>
              </div>
            </div>
          </Transition>
        </div>
      )}

      {isMobileView && (
        <div
          className={cn(
            disabled ? 'bottom-24' : 'bottom-20',
            'fixed right-0 left-0 z-20 h-0 shadow-lg drop-shadow-[0_0_12px_rgba(0,0,0,0.15)]',
          )}
          id="StickyBuyButton_Mobile"
        >
          <Transition
            as="div"
            show={isBuyButtonVisible}
            enter="transition-opacity duration-750"
            enterFrom="opacity-0"
            enterTo="opacity-95"
            leave="transition-opacity duration-750"
            leaveFrom="opacity-95"
            leaveTo="opacity-0"
          >
            <ProductBuyButton
              size="medium"
              id={BuyButtonId.StickyBuyButton_Mobile}
              product={{ sku: product.sku, name: product.name, price: product.price, type: product.type }}
              selectedModifiers={selectedModifiers}
              className={cn(
                disabled ? 'h-24' : 'h-20',
                'flex items-center justify-center rounded-t-2xl bg-selphWhite-100 text-center lg:hidden',
              )}
              disabled={disabled}
            >
              <>
                <span className="text-xs sm:whitespace-nowrap">Includes Money Back Guarantee</span>
                {product.sku === 'LT-QFBCT' && (
                  <span className="text-xs sm:whitespace-nowrap">& Free Charity Donation</span>
                )}
              </>
            </ProductBuyButton>
          </Transition>
        </div>
      )}
    </>
  )
}

export default StickyBuyButton

/**
 * The threshold value (in pixels) determines the minimum scroll distance from the top of the page
 * before the sticky buy button becomes visible. This helps to ensure that the sticky buy button
 * does not cover the navigation bar at the top of the page when the viewport is short.
 *
 * Adjust this value as needed.
 * @constant {number}
 */
const threshold = 100
