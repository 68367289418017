import { useCallback, useMemo, useReducer } from 'react'
import { BloodCollectionOption, LabTestProduct, SampleType } from '../../gql'
import { nonNullable } from '../../helpers/filter-boolean'

export interface RecommendedAddons {
  testAddon: {
    code: string
    name: string
    price: number
    sample: SampleType
    capillary?: boolean | null
    test: {
      sample: SampleType
      capillary?: boolean | null
      friendlyName?: string | null
    }
  }
  why: string
}
interface ProductDetailsProps {
  productPrice: number
  recommendedAddons: RecommendedAddons[]
  availableBco: BloodCollectionOption[]
  bco: BloodCollectionOption[]
  modifiers: string[]
}
interface InitialStateProps {
  availableBco: BloodCollectionOption[]
  bco: BloodCollectionOption[]
  recommendedAddons: RecommendedAddons[]
  modifiers: string[]
}

type ModalType = {
  type: 'BcoModal' | 'AddOnsModal'
  props: {
    addonToAdd?: string
    availableBco?: BloodCollectionOption[]
    selectedAddons?: Set<string>
    availableAddons?: RecommendedAddons[]
    temporaryAddons?: Set<string>
  }
} | null

export interface ProductDetailsState {
  /**
   * All Blood Collection Options from the "blood-collection-options.ts" file
   */
  bco: BloodCollectionOption[]
  /**
   * Default Blood Collection Options for the base product
   * - when "STOOL", it should be empty
   * - when "BLOOD", it should have the available BCO's based on "capillary" property
   */
  defaultBco: BloodCollectionOption[]
  /**
   * Available Blood Collection Options for the base product with the selected addons
   */
  availableBco: BloodCollectionOption[]
  /**
   * Currently selected Blood Collection Option by te user
   */
  selectedBco: BloodCollectionOption | null
  /**
   * All available addOns for a base product
   */
  availableAddons: RecommendedAddons[]
  /**
   * All selected addOns by the user, including the selected BCO
   */
  selectedAddons: Set<string>
  selectedClinicId: string | null

  modal: ModalType
}

export type ProductDetailsFromState = ReturnType<typeof useProductDetails>

export function useProductDetails({
  productPrice,
  recommendedAddons,
  availableBco,
  bco,
  modifiers,
}: ProductDetailsProps) {
  const initialProductState: ProductDetailsState = getInitialState({
    bco,
    availableBco,
    recommendedAddons,
    modifiers,
  })

  const [state, dispatch] = useReducer(productReducer, initialProductState)

  const setSelectedBco = useCallback((bco: ProductDetailsState['bco'][0]) => {
    dispatch({ type: 'SET_SELECTED_BCO', payload: bco })
  }, [])

  const setSelectedClinicId = useCallback((clinicId: string) => {
    dispatch({ type: 'SET_SELECTED_CLINIC', payload: clinicId })
  }, [])

  const toggleAddons = useCallback((addon: string, selectedBco?: ProductDetailsState['bco'][0]) => {
    dispatch({ type: 'TOGGLE_ADDON', payload: { addon, selectedBco } })
  }, [])

  const closeModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' })
  }, [])

  const showBcoModal = useCallback((addonToAdd: string, availableBco: BloodCollectionOption[]) => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: { addonToAdd, availableBco },
    })
  }, [])

  const showAddOnsModal = useCallback(() => {
    dispatch({
      type: 'SHOW_ADDONS_MODAL',
      payload: {
        selectedAddons: state.selectedAddons,
        availableAddons: state.availableAddons,
      },
    })
  }, [state.selectedAddons, state.availableAddons])

  const updateTemporaryAddons = useCallback((addons: Set<string>) => {
    dispatch({
      type: 'UPDATE_TEMPORARY_ADDONS',
      payload: addons,
    })
  }, [])

  const confirmAddOns = useCallback(() => {
    dispatch({ type: 'CONFIRM_ADDONS' })
  }, [])

  const resetState = useCallback((state: ProductDetailsState) => {
    dispatch({ type: 'RESET_STATE', payload: state })
  }, [])

  const newModifiers = useMemo(
    () => computeModifiersFromState(state),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedBco, state.selectedAddons, state.selectedClinicId],
  )
  const price = useMemo(
    () => computePriceFromModifiers(productPrice, recommendedAddons, newModifiers, bco),
    [productPrice, recommendedAddons, newModifiers, bco],
  )

  const disableBuyButton = useMemo(() => {
    if (!state.selectedBco) return false

    return state.selectedBco?.code.startsWith('BCO-IVD-CLINIC') && !state.selectedClinicId
  }, [state.selectedBco, state.selectedClinicId])

  return {
    state,
    modifiers: newModifiers,
    price,
    setSelectedBco,
    setSelectedClinicId,
    toggleAddons,
    closeModal,
    showBcoModal,
    showAddOnsModal,
    updateTemporaryAddons,
    confirmAddOns,
    resetState,
    getInitialState,
    disableBuyButton,
  }
}

export const getRecommendedAddonsData = (product: LabTestProduct) => {
  const fullAddonData = product.recommendedAddons.map((addon) => {
    return {
      ...addon,
      testAddon: {
        ...addon.testAddon,
        capillary: addon.testAddon.test.capillary,
        sample: addon.testAddon.test.sample,
      },
    }
  })

  return fullAddonData
}

export const getInitialState = ({
  bco,
  availableBco,
  recommendedAddons,
  modifiers,
}: InitialStateProps): ProductDetailsState => {
  let initialProductState: ProductDetailsState = {
    bco,
    defaultBco: availableBco,
    availableBco,
    selectedBco: availableBco.find((b) => b.code === 'BCO-CAP') || availableBco[0] || null,
    availableAddons: recommendedAddons,
    selectedAddons: new Set(),
    selectedClinicId: null,
    modal: null,
  }

  if (modifiers) {
    const selectedModifiers = new Set<string>()

    modifiers.forEach((modifier) => {
      selectedModifiers.add(modifier)
    })

    if (selectedModifiers.size > 0) {
      const bcoFromModifier = Array.from(selectedModifiers).find((entry) => entry.startsWith('BCO-'))

      let selectedBco = undefined
      let selectedClinicId = undefined

      if (bcoFromModifier) {
        selectedBco = bco.find((bco) => bcoFromModifier.startsWith(bco.code))

        if (selectedBco?.code === 'BCO-IVD-CLINIC') {
          selectedClinicId = bcoFromModifier.slice('BCO-IVD-CLINIC-'.length)

          initialProductState.selectedClinicId = selectedClinicId
        }

        if (selectedBco) {
          initialProductState = setSelectedBcoReducer(initialProductState, {
            type: 'SET_SELECTED_BCO',
            payload: selectedBco,
          })
        }
      }

      const addOnsFromModifiers = Array.from(selectedModifiers).filter((entry) => entry.startsWith('T-'))

      for (const addon of addOnsFromModifiers) {
        initialProductState = toggleAddonReducer(initialProductState, {
          type: 'TOGGLE_ADDON',
          payload: { addon, selectedBco },
        })
      }
    }
  }

  return initialProductState
}

type SetSelectedBcoAction = { type: 'SET_SELECTED_BCO'; payload: ProductDetailsState['bco'][0] }
type SetSelectedClinicAction = { type: 'SET_SELECTED_CLINIC'; payload: string }
type ToggleAddonAction = {
  type: 'TOGGLE_ADDON'
  payload: { addon: string; selectedBco?: ProductDetailsState['bco'][0] }
}
type CloseModalAction = { type: 'CLOSE_MODAL' }
type ConfirmModalAction = { type: 'CONFIRM_MODAL' }
type ShowModalAction = { type: 'SHOW_MODAL'; payload: { addonToAdd: string; availableBco: BloodCollectionOption[] } }
type ShowAddOnsModalAction = {
  type: 'SHOW_ADDONS_MODAL'
  payload: {
    selectedAddons: Set<string>
    availableAddons: RecommendedAddons[]
  }
}
type UpdateTemporaryAddonsAction = {
  type: 'UPDATE_TEMPORARY_ADDONS'
  payload: Set<string>
}
type ConfirmAddOnsAction = {
  type: 'CONFIRM_ADDONS'
}
type ResetStateAction = { type: 'RESET_STATE'; payload: ProductDetailsState }

type Action =
  | SetSelectedBcoAction
  | SetSelectedClinicAction
  | ToggleAddonAction
  | CloseModalAction
  | ConfirmModalAction
  | ShowModalAction
  | ShowAddOnsModalAction
  | UpdateTemporaryAddonsAction
  | ConfirmAddOnsAction
  | ResetStateAction

const productReducer = (state: ProductDetailsState, action: Action): ProductDetailsState => {
  switch (action.type) {
    case 'SET_SELECTED_BCO':
      return setSelectedBcoReducer(state, action)

    case 'SET_SELECTED_CLINIC':
      return {
        ...state,
        selectedClinicId: action.payload,
      }

    case 'TOGGLE_ADDON':
      return toggleAddonReducer(state, action)

    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: null,
      }

    case 'RESET_STATE':
      return action.payload

    case 'SHOW_MODAL':
      return {
        ...state,
        modal: {
          type: 'BcoModal',
          props: {
            addonToAdd: action.payload.addonToAdd,
            availableBco: action.payload.availableBco,
          },
        },
      }

    case 'SHOW_ADDONS_MODAL':
      return {
        ...state,
        modal: {
          type: 'AddOnsModal',
          props: {
            selectedAddons: action.payload.selectedAddons,
            availableAddons: action.payload.availableAddons,
            temporaryAddons: new Set(state.selectedAddons),
          },
        },
      }

    case 'UPDATE_TEMPORARY_ADDONS':
      if (state.modal?.type !== 'AddOnsModal') return state
      return {
        ...state,
        modal: {
          ...state.modal,
          props: {
            ...state.modal.props,
            temporaryAddons: action.payload,
          },
        },
      }

    case 'CONFIRM_ADDONS':
      if (state.modal?.type !== 'AddOnsModal' || !state.modal.props.temporaryAddons) return state

      return recalculateAvailableBco({
        ...state,
        selectedAddons: new Set(state.modal.props.temporaryAddons),
        modal: null,
      })

    default:
      return state
  }
}

const setSelectedBcoReducer = (state: ProductDetailsState, action: SetSelectedBcoAction): ProductDetailsState => {
  if (state.availableBco.find((bco) => bco.code === action.payload.code)) {
    return {
      ...state,
      selectedBco: action.payload,
    }
  }

  return state
}

const toggleAddonReducer = (state: ProductDetailsState, action: ToggleAddonAction): ProductDetailsState => {
  // Get the initial availableBco length to be compared later
  const initialAvailableBco = state.availableBco

  const newSelectedAddons = new Set(state.selectedAddons)
  const newAddon = action.payload.addon
  const forceSelectedBco = action.payload.selectedBco

  if (newSelectedAddons.has(newAddon)) {
    newSelectedAddons.delete(newAddon)
  } else {
    newSelectedAddons.add(newAddon)
  }

  // Get the full object data for the selectedAddOns
  const selectedAddons = Array.from(newSelectedAddons)
    .map((addon) => state.availableAddons.find((a) => a.testAddon.code === addon))
    .filter(nonNullable)

  // Get all blood addons
  const selectedBloodAddons = selectedAddons.filter((addon) => addon.testAddon.test.sample === SampleType.Blood)

  // Check if all of the selected blood addons can do capillary
  const canDoCapillary = selectedBloodAddons.every((addon) => addon.testAddon.test.capillary)

  // Set the default for product (BCO for BLOOD and [] for STOOL)
  let availableBco: BloodCollectionOption[] = [...state.defaultBco]
  if (selectedBloodAddons.length > 0) {
    availableBco = canDoCapillary ? state.bco : state.bco.filter((b) => b.code !== 'BCO-CAP')
  }

  // Check if availableBco has changed
  const availableBcoChanged =
    initialAvailableBco.length !== availableBco.length ||
    !initialAvailableBco.every((bco, idx) => bco.code === availableBco[idx]?.code)

  // Check if selectedBco is still in the new availableBco list
  const selectedBcoInAvailableBco = availableBco.some((bco) => bco.code === state.selectedBco?.code)

  // Determine if the modal should be shown
  const showModal = availableBcoChanged && !selectedBcoInAvailableBco && availableBco.length > 0

  if (showModal && !forceSelectedBco) {
    const modal: ProductDetailsState['modal'] = {
      type: 'BcoModal',
      props: {
        addonToAdd: newAddon,
        availableBco,
      },
    }

    return {
      ...state,
      modal,
    }
  }

  // Update selectedBco only if availableBco has changed and it is not in the new availableBco list
  const selectedBco = forceSelectedBco ? forceSelectedBco : availableBco.length ? state.selectedBco : null

  return {
    ...state,
    availableBco,
    selectedBco,
    selectedAddons: newSelectedAddons,
    modal: null,
  }
}

const computeModifiersFromState = (state: ProductDetailsState): Set<string> => {
  const modifiers = new Set<string>()

  if (state.selectedBco) {
    if (state.selectedBco.code.startsWith('BCO-IVD-CLINIC') && state.selectedClinicId) {
      modifiers.add(`${state.selectedBco.code}-${state.selectedClinicId}`)
    } else {
      modifiers.add(state.selectedBco.code)
    }
  }

  if (state.selectedAddons) {
    state.selectedAddons.forEach((addon) => {
      if (!addon.startsWith('BCO-')) {
        modifiers.add(addon)
      }
    })
  }

  return modifiers
}

const computePriceFromModifiers = (
  productPrice: number,
  recommendedAddons: RecommendedAddons[],
  newModifiers: Set<string>,
  bco: BloodCollectionOption[],
): number => {
  const price = [...newModifiers].reduce((acc, curr) => {
    const bcoData = bco.find((option) => curr.startsWith(option.code))
    const addon = recommendedAddons.find((addon) => addon.testAddon.code === curr)

    return acc + (bcoData?.price ?? 0) + (addon?.testAddon.price ?? 0)
  }, productPrice)

  return price
}

// Helper function to recalculate available BCO after confirming addons
const recalculateAvailableBco = (state: ProductDetailsState): ProductDetailsState => {
  // Get the full object data for the selectedAddOns
  const selectedAddons = Array.from(state.selectedAddons)
    .map((addon) => state.availableAddons.find((a) => a.testAddon.code === addon))
    .filter(nonNullable)

  // Get all blood addons
  const selectedBloodAddons = selectedAddons.filter((addon) => addon.testAddon.test.sample === SampleType.Blood)

  // Check if all of the selected blood addons can do capillary
  const canDoCapillary = selectedBloodAddons.every((addon) => addon.testAddon.test.capillary)

  // Set the default for product (BCO for BLOOD and [] for STOOL)
  let availableBco: BloodCollectionOption[] = [...state.defaultBco]
  if (selectedBloodAddons.length > 0) {
    availableBco = canDoCapillary ? state.bco : state.bco.filter((b) => b.code !== 'BCO-CAP')
  }

  // Check if selectedBco is still valid with new availableBco
  const selectedBco = availableBco.find((bco) => bco.code === state.selectedBco?.code) || availableBco[0] || null

  return {
    ...state,
    availableBco,
    selectedBco,
  }
}
