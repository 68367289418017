import { useRouter } from 'next/router'
import React from 'react'
import { useBasket } from '../checkout/use-basket'
import { SolidButtonProps } from '../ui/button/solid-button'
import { ProductType } from '../../gql'
import { useMailchimp } from '../user/use-mailchimp'
import { useEngagement } from '../user/use-engagement'
import * as UI from '@/ui'

export type PaymentProps = {
  sku: string
  name: string
  price: number
  type: ProductType
}

export const BuyButtonId = Object.freeze({
  BuyButton_1: 'buyButton_1',
  BuyButton_2: 'buyButton_2',
  StickyBuyButton_Desktop: 'stickyBuyButton_Desktop',
  StickyBuyButton_Mobile: 'stickyBuyButton_Mobile',
})

export type ProductBuyButtonProps = {
  product: PaymentProps
  selectedModifiers?: Set<string>
  errorMessage?: string
  className?: string
  size?: SolidButtonProps['size']
  id: (typeof BuyButtonId)[keyof typeof BuyButtonId]
  cta?: string
  disabled?: boolean
  children?: React.ReactNode
}

export const ProductBuyButton = ({
  className,
  product,
  selectedModifiers = new Set(),
  size = 'medium',
  id,
  cta,
  disabled,
  errorMessage,
  children,
}: ProductBuyButtonProps) => {
  const router = useRouter()
  const { addToBasket } = useBasket()
  const { updateTags } = useMailchimp()
  const { recentEmail } = useEngagement()
  if (!product) return null

  const addProductToBasket = () => {
    addToBasket({
      sku: product.sku,
      name: product.name,
      price: product.price,
      modifiers: [...selectedModifiers],
    })
    recentEmail && updateTags({ email: recentEmail, tags: [{ name: 'browsing', status: 'inactive' }] })
    router.push('/checkout/cart')
  }

  return (
    <div className={className}>
      <UI.Button
        id={id}
        size={size}
        theme="solid"
        onClick={addProductToBasket}
        disabled={disabled}
        errorMessage={disabled ? errorMessage || 'Please select a clinic for your blood draw.' : ''}
      >
        <div className="flex flex-col items-center justify-center">
          <span className="md:text-lg">{cta || 'Add to Cart'}</span>
          {children}
        </div>

        <span className="sr-only">, {product.name}</span>
      </UI.Button>
    </div>
  )
}

export default ProductBuyButton
