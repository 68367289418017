import { useMemo, useState } from 'react'
import { BloodCollectionOption } from '../../gql'
import BloodCollectionMethod from './blood-collection-method'
import { ProductDetailsFromState } from './use-product-details'
import * as UI from '@/ui'

type ProductBcoConfirmationModalProps = {
  productDetails: ProductDetailsFromState
  addonToAdd?: string
  availableBco?: BloodCollectionOption[]
}

const ProductBcoConfirmationModal = ({
  productDetails,
  addonToAdd = '',
  availableBco = [],
}: ProductBcoConfirmationModalProps) => {
  const { state, closeModal, toggleAddons, setSelectedClinicId } = productDetails
  const [selectedBco, setSelectedBco] = useState<BloodCollectionOption>(state.selectedBco || availableBco[0])

  const modalDescription = useMemo(
    () => (availableBco && availableBco.map((bco) => bco.code).includes('BCO-CAP') ? bloodAddOnMsg : venousDrawMsg),
    [availableBco],
  )

  if (!availableBco) throw new Error('availableBco is required')

  const isClinicSelectionRequired = selectedBco?.code === 'BCO-IVD-CLINIC' && !state.selectedClinicId

  return (
    <UI.Modal color="light" show={!!productDetails} onClose={closeModal}>
      <UI.Block gap="small" className="max-w-lg">
        <UI.Block gap="xs">
          <UI.Heading as="p" size={{ default: 'xs', lg: 'small' }}>
            Blood Sample Collection
          </UI.Heading>
          <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="leading-tight">
            {modalDescription}
          </UI.Paragraph>
        </UI.Block>

        <BloodCollectionMethod
          bco={selectedBco}
          onChange={setSelectedBco}
          availableBco={availableBco}
          clinicId={state.selectedClinicId}
          onClinicChange={setSelectedClinicId}
        />

        <UI.Button
          size="full"
          onClick={() => {
            toggleAddons(addonToAdd, selectedBco)
          }}
          disabled={isClinicSelectionRequired}
          errorMessage={isClinicSelectionRequired ? 'Please select a clinic.' : ''}
          className="mx-auto mt-6"
        >
          Make Selection To Proceed
        </UI.Button>
      </UI.Block>
    </UI.Modal>
  )
}

export default ProductBcoConfirmationModal

const bloodAddOnMsg = 'This add-on is a blood test. Please choose your preferred blood collection method to continue.'
const venousDrawMsg =
  'This add-on requires a venous blood draw. Please choose your preferred blood collection method to continue.'
