import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import * as UI from '@/ui'

const MoneyBackGuarantee = ({
  children,
  className = '',
  panelClassName = '',
}: {
  children: React.ReactNode
  className?: string
  panelClassName?: string
}) => {
  return (
    <Popover className={`inline ${className}`}>
      <PopoverButton className="relative mr-4 cursor-pointer underline focus:outline-hidden">
        <span>{children}</span>
        <InformationCircleIcon className="absolute -top-1 -right-[18px] h-5 w-5" />
      </PopoverButton>

      <PopoverPanel
        className={`absolute z-20 rounded-xl bg-selphWhite-100 px-4 py-4 text-left shadow-2xl sm:text-lg md:text-xl ${panelClassName}`}
      >
        <UI.Block className="text-selphBlack">
          <UI.Paragraph>
            We&apos;re very confident in our service and want you to be too. That&apos;s why all our tests are covered
            by our <span className="font-normal">Money Back Guarantee</span>.
          </UI.Paragraph>
          <UI.Paragraph>
            If there&apos;s any issue with your sample that stops the lab being able to analyse it,{' '}
            <span className="font-normal">we&apos;ll send you a replacement kit to test again free of charge</span>.
          </UI.Paragraph>
          <UI.Paragraph>
            If we still can&apos;t get a result for you (or you prefer not to retest){' '}
            <span className="font-normal">
              we&apos;ll always refund you for any test that couldn&apos;t be completed
            </span>
            . You&apos;ll never be charged for a test result you didn&apos;t receive.
          </UI.Paragraph>
          <UI.Paragraph>
            See our{' '}
            <UI.Link to="terms-of-service" type="text" color="black" highlight="none">
              Terms of Service
            </UI.Link>{' '}
            for more info.
          </UI.Paragraph>
        </UI.Block>
      </PopoverPanel>
    </Popover>
  )
}

export default MoneyBackGuarantee
