import { useEffect, useState } from 'react'
import { RadioGroup, Description, Radio, Label } from '@headlessui/react'
import every from 'lodash-es/every'
import { BloodCollectionOption, LabTestDataFragment, LabTestProduct, ProductTestGroup, SampleType } from '../../gql'
import { cn } from '../../helpers/tailwind'
import { ClinicProvider } from '../../pages/api/nearby-clinics.api'
import { ClinicDataResponse } from '../../pages/api/clinic-info.api'
import { fetchClinicData } from '../account/kit-activation/clinic-selector'
import ClinicLocationsModal from './clinic-locations-modal'
import Tick_Icon from '@/images/icons/tick_icon.svg?svgr'
import * as UI from '@/ui'

export type BloodCollectionMethodProps = {
  bco: BloodCollectionOption | null
  availableBco: BloodCollectionOption[]
  onChange: (value: BloodCollectionOption) => void
  clinicId: string | null
  onClinicChange: (clinicId: string) => void
}

const providers: ClinicProvider[] = ['superdrug', 'tuli']

const BloodCollectionMethod = ({
  bco,
  availableBco,
  onChange,
  clinicId,
  onClinicChange,
}: BloodCollectionMethodProps) => {
  const [seeClinicLocations, setSeeClinicLocations] = useState(false)
  const [clinic, setClinic] = useState<ClinicDataResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!clinicId) return

    setIsLoading(true)
    ;(async () => {
      const response = await fetchClinicData({ id: clinicId })

      setClinic(response || null)
      setIsLoading(false)
    })()
  }, [clinicId])

  return (
    <RadioGroup value={bco || availableBco[0]} onChange={onChange} className="w-full">
      <UI.Block display="flexCol" gap="small">
        {availableBco.map((method) => (
          <Radio
            key={method.name}
            value={method}
            className={({ checked }) =>
              ` ${
                checked ? 'bg-selphGreen-400 text-selphWhite-500' : 'bg-selphWhite-300'
              } group mt-0.5 cursor-pointer rounded-lg px-2 py-3 hover:bg-selphGreen-400 focus:outline-hidden sm:p-5`
            }
          >
            {({ checked }) => (
              <>
                <Label as="div" className="mr-2 md:mr-4">
                  <div className="flex items-center justify-between gap-x-2">
                    <div className="flex items-start gap-x-2">
                      {checked ? (
                        <span className="mt-0.5 flex size-5 shrink-0 items-center justify-center rounded-full border border-selphOrange-500 bg-selphOrange-500 p-0.5 text-selphWhite-300">
                          <Tick_Icon className="h-4" />
                        </span>
                      ) : (
                        <span className="mt-0.5 block size-5 shrink-0 rounded-full border border-selphOrange-500 bg-selphWhite-300 group-hover:bg-selphOrange-500" />
                      )}

                      <UI.Paragraph
                        size="medium"
                        className="pr-3 leading-tight font-normal group-hover:text-selphWhite-500"
                        color={checked ? 'white' : 'black'}
                      >
                        {checked && method.name.includes('clinic') && clinic?.name
                          ? `Have a venous blood draw at:`
                          : method.name}
                      </UI.Paragraph>
                    </div>

                    <UI.Paragraph
                      className={`mr-2 leading-none font-normal md:mr-0 ${
                        checked ? 'text-selphWhite-500' : 'text-selphBlack group-hover:text-selphWhite-500'
                      }`}
                    >
                      {method.price === 0 ? (
                        <span>Free</span>
                      ) : (
                        <UI.Currency inputUnit="pence" value={method.price}></UI.Currency>
                      )}
                    </UI.Paragraph>
                  </div>
                </Label>

                <Description
                  as="div"
                  className={`ml-6 flex justify-between ${checked ? 'text-selphWhite-500' : 'text-selphBlack'}`}
                >
                  <div className="font-normal">
                    {checked && (
                      <UI.Block className="mt-2">
                        {method.code === 'BCO-IVD-CLINIC' && (
                          <UI.Block gap="none">
                            <UI.Button
                              type="text"
                              color="none"
                              onClick={(e) => {
                                e.stopPropagation()
                                setSeeClinicLocations(true)
                              }}
                              isRunning={isLoading}
                              className={cn(
                                clinic
                                  ? 'border-0 px-0 py-0 text-lg underline hover:text-selphAmber-500'
                                  : 'border-2 px-2 py-1 hover:bg-selphAmber-500',
                                'rounded-lg border-selphAmber-500 text-selphWhite-500 outline-0',
                              )}
                            >
                              {clinic ? clinic.name : 'Select your nearest clinic'}
                            </UI.Button>
                          </UI.Block>
                        )}

                        <UI.Paragraph className="leading-tight" color="white">
                          {method.description}
                        </UI.Paragraph>
                      </UI.Block>
                    )}
                  </div>
                </Description>
              </>
            )}
          </Radio>
        ))}
      </UI.Block>

      <ClinicLocationsModal
        providers={providers}
        show={seeClinicLocations}
        onClinicChange={onClinicChange}
        onClose={() => setSeeClinicLocations(!seeClinicLocations)}
      />
    </RadioGroup>
  )
}

export default BloodCollectionMethod

/**
 * Gets the default blood collection option for a product:
 * - If the product can do capillary blood collection, return the first blood collection option `BCO-CAP`;
 * - Otherwise, return the second blood collection option `BCO-IVD-CLINIC`;
 * - The `BloodCollectionMethods` are defined in the `bloodCollectionTypes` array in the `data/blood-collection-options.ts` file
 * @param product - The product to get the default blood collection option for
 * @returns string - The default blood collection option code
 */
export const getDefaultBco = (product: LabTestProduct | LabTestDataFragment): string => {
  const hasBloodTest = product.bloodCollectionOptions?.[0] ? true : false
  const canDoCapillaryTest = canDoCapillary(product)

  return canDoCapillaryTest && hasBloodTest
    ? product?.bloodCollectionOptions[0].code
    : product?.bloodCollectionOptions[1].code
}

/**
 * Checks if a product can do capillary blood collection by checking if all tests from all testGroups of type "SampleType.Blood" can do capillary blood collection
 * @param product - The product to check
 * @returns boolean - Returns true if the product can do capillary blood collection, otherwise false
 */
export const canDoCapillary = (product: LabTestProduct | LabTestDataFragment): boolean => {
  return every(product.testGroups, (group: ProductTestGroup) =>
    every(group.tests, (test) => {
      if (test.sample === SampleType.Blood) {
        return test.capillary ? true : false
      }
      return true
    }),
  )
}
