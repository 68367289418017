import { useEffect, useState } from 'react'

const useScreenView = () => {
  const [isMobileView, setIsMobileView] = useState(false)
  const [isDesktopView, setIsDesktopView] = useState(false)

  useEffect(() => {
    const mediaQueryMobile = window.matchMedia('(max-width: 768px)')
    const mediaQueryDesktop = window.matchMedia('(min-width: 769px)')

    const handleChange = () => {
      if (mediaQueryMobile.matches) {
        setIsMobileView(true)
        setIsDesktopView(false)
      } else if (mediaQueryDesktop.matches) {
        setIsDesktopView(true)
        setIsMobileView(false)
      }
    }

    mediaQueryMobile.addEventListener('change', handleChange)
    mediaQueryDesktop.addEventListener('change', handleChange)

    handleChange()

    return () => {
      mediaQueryMobile.removeEventListener('change', handleChange)
      mediaQueryDesktop.removeEventListener('change', handleChange)
    }
  }, [])

  return { isMobileView, isDesktopView }
}

export default useScreenView
